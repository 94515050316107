import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import Play from './Play';

function App() {
  return (
    <Router>
      <div className="App">
        
        <div className="content">
          <Routes>
          <Route exact path="/" element={<div><Navbar/><Home/></div>} />
          <Route path="/play/:quizid" element={<Play/>} />
          <Route path="/play" element={<Play/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
