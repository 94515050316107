
const Home = () => {
    console.log("home page");
    return (
        <div className="home">
            <a href="play/verylandscape"><button className="btnism">EXAMPLE VERY LANDSCAPE</button></a><br/>
            <a href="play/squarish"><button className="btnism">EXAMPLE SQUARISH</button></a><br/>
            <a href="play/baroque"><button className="btnism">BAROQUE</button></a><br/>
            <a href="play/cubism"><button className="btnism">CUBISM</button></a>(none)<br/>
            <a href="play/expressionism"><button className="btnism">EXPRESSIONISM</button></a>(none yet)<br/>
            <a href="play/impressionism"   ><button className="btnism">IMPRESSIONISM</button></a><br/>
            <a href="play/neoclassicism"><button className="btnism">NEOCLASSICISM</button></a><br/>
            <a href="play/postimpressionism"><button className="btnism">POST-IMPRESSIONISM</button></a><br/>
            <a href="play/realism"><button className="btnism">REALISM</button></a><br/>
            <a href="play/renaissance"><button className="btnism">RENAISSANCE</button></a><br/>
            <a href="play/rococo"><button className="btnism">ROCOCO</button></a><br/>
            <a href="play/romanticism"><button className="btnism">ROMANTICISM</button></a><br/>
            <a href="play/surrealism"><button className="btnism">SURREALISM</button></a>(none yet)<br/>
            <a href="play/symbolism"><button className="btnism">SYMBOLISM</button></a>(none)<br/>
            <a href="play/all"><button className="btnism">ALL</button></a>
        </div>        
    )
}

export default Home;
