import { useState } from "react";
import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import {InlineDb_howmanyartworks, InlineDb_artworks, InlineDb_artists, renaissanceArtists, InlineDb_Baroque, InlineDb_impressionist, neoclassicismArtists, postImpressionismArtists, realismArtists, rococoArtists, romanticismArtists, symbolismArtists, surrealistPainters, expressionistPainters, cubistArtists} from "./InlineData"
import {specificsequence, specific, specific_url, squarish, verylandscape} from "./InlineData"

const portion_horizontal_for_img = 0.8;//0.66
var howmany_multichoice = 6;
var howmany_questions = 50;
//var all_artists = //include_lesser_known ? InlineDb_artists:InlineDb_impressionist;
var viewport_width = 0;
var viewport_height = 0;    
var all_artists = [];
var json_questions = [];
const logimg = true;
const logprep = false;
const logstatus = false;
const lograndom = false;
const logfetch = false;
const show_remaining = false;
document.addEventListener('keydown', (e) => {
    if (e.key === "Enter")
    {
        const button = document.getElementById("btnNext");         

        if (!button)
        {
            const againbutton = document.getElementById("btnAgain");
            if (againbutton) againbutton.click();
        }
        else
        {
            if (button) button.click();            
        }
    }
    else if (e.key === "1")
    {
        const button = document.getElementById("answer1"); if (button) button.click();
    }
    else if (e.key >= "1" && e.key <="9")
    {
        const button = document.getElementById("answer" + e.key); if (button) button.click();
    }
});

// created by gemini
function getIntrinsicDimensions(imageElement) {
    return new Promise((resolve) => {
      if (imageElement.complete) {
        resolve({ width: imageElement.naturalWidth, height: imageElement.naturalHeight });
      } else {
        imageElement.onload = () => {
          resolve({ width: imageElement.naturalWidth, height: imageElement.naturalHeight });
        };
      }
    });
}

function HideImg()
{
    // var img = document.getElementById('artworkimg');
    // var targetDiv = document.getElementById('hiddenimgparent');
    // targetDiv.appendChild(img);
    // targetDiv.classList.remove('hidden');
    // console.log("image has been hidden");
}
function UnhideImg()
{
    // var img = document.getElementById('artworkimg');
    // var targetDiv = document.getElementById('paintingdiv');
    // targetDiv.appendChild(img);
    // targetDiv.classList.remove('hidden');
    // console.log("image has been unhidden");
}

function MoveCellsIntoVerticalTable()
{
    var cell = document.getElementById('choicecell');
    var targetDiv = document.getElementById('verticalchoice');
    targetDiv.appendChild(cell);
    targetDiv.classList.remove('hidden');

    cell = document.getElementById('paintingcell');
    targetDiv = document.getElementById('verticalpainting');
    targetDiv.appendChild(cell);
    targetDiv.classList.remove('hidden');

    var table0 = document.getElementById('verticaltable');
    var table1 = document.getElementById('horizontaltable');
    table0.style.display = "";
    table1.style.display = "none";
    console.log("cells moved into vertical table");
}

function MoveCellsIntoHorizontalTable()
{
    var cell = document.getElementById('choicecell');
    var targetDiv = document.getElementById('horizontalchoice');
    targetDiv.appendChild(cell);
    targetDiv.classList.remove('hidden');

    cell = document.getElementById('paintingcell');
    targetDiv = document.getElementById('horizontalpainting');
    targetDiv.appendChild(cell);
    targetDiv.classList.remove('hidden');

    var table0 = document.getElementById('horizontaltable');
    var table1 = document.getElementById('verticaltable');
    table0.style.display = "";
    table1.style.display = "none";
    console.log("cells moved into horizontal table");
}

// created by gemini
function getLineHeightUsingDiv(targetElement) {
    const fontSize = targetElement.style.fontSize;
    const fontFamily = targetElement.style.fontFamily;
    const fontWeight = targetElement.style.fontWeight; // Optional, include if needed
  
    // Combine font properties into a CSS font style string
    const font = `${fontWeight} ${fontSize} ${fontFamily}`;
  
    const container = document.createElement("div");
    container.style.font = font;
    container.style.visibility = "hidden";
    //container.textContent = "This is a line of text"; // Or any test string
    container.innerHTML = "1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>";
  
    document.body.appendChild(container);
  
    const height = container.offsetHeight;
  
    container.remove();
    return height;
  }
// let response = await fetch("http://localhost:3001/books?_page=1");
// let total = response.headers.get('X-Total-Count');

const Play = () => {
    const { quizid } = useParams();
    const [CountCorrect, setCountCorrect] = useState(0);
    const [statusGameCreation, setStatusGameCreation] = useState("");
    const [FinalScore, setFinalScore] = useState('');
    const [answer, setAnswer] = useState('');
    const [possibles, setPossibles] = useState([]);
    const [imageurl_s, setImageUrl] = useState('');
    const [next_imageurl_s, setNextImageUrl] = useState('');
    const [correct_artist, setCorrectArtist] = useState('');
    const [selection_artwork_info, set_selection_artwork_info] = useState([]);

    //
    // FetchOneArtworkDataFirstIdGivenRecursive
    // load one artwork, corresponding to the first id in the given array
    // then recursively call self with the remaining items
    //
    const FetchOneArtworkDataFirstIdGivenRecursive = async (ids) => {
        if (logfetch) console.log("FetchOneArtworkDataFirstIdGivenRecursive - ids = " + ids);
        if (!ids.length){
            if (logstatus) console.log("changing status to loaded");
            setStatusGameCreation("loaded");
            return; // Base case: no more IDs to process
        }

        const id = ids[0];
        const one_url = "http://localhost:8000/artworks?id=" + id;
        const response = await fetch(one_url);
        const data = await response.json();
        if (logfetch) console.log("one_url = " + one_url);
        if (logfetch) console.log("data = " + data[0].artist);
        const selection_artwork_info = data[0];//.artist;//selection_artwork_info[0][0];
        if (selection_artwork_info)
        {
            set_selection_artwork_info((prevData) => [...prevData, selection_artwork_info]);
        }
        if (logfetch) console.log("FetchOneArtworkDataFirstIdGivenRecursive - awi[0] = " + selection_artwork_info[0].artist);

        FetchOneArtworkDataFirstIdGivenRecursive(ids.slice(1)); // Call recursively with remaining IDs
    };

    const PopulateSpecificArtworkSequence = (sequence) => {
        sequence.forEach(
            (one_artwork_info) => {
                console.log(" " + one_artwork_info.url);
                set_selection_artwork_info((prevData) => [...prevData, one_artwork_info]);
            }
        );
        howmany_questions = selection_artwork_info.length;
    }

    //
    // FetchAllArtworkData
    // load data for a random selection of artworks
    //
    const FetchAllArtworkData = async () => {

        if (quizid === "specificsequence")
        {
            console.log("specificsequence...");
            PopulateSpecificArtworkSequence(specificsequence);
            setStatusGameCreation("loaded");
            return;
        }
        
        const howmanyartworks = InlineDb_howmanyartworks;
        
        if (logfetch) console.log("all_artists_array = " + all_artists)

        if (howmany_questions > howmanyartworks)
        {
            howmany_questions = howmanyartworks;
        }
        var artwork_ids = [];
        var dbg9 = 0;

        if (logfetch) console.log("filtering artworks");
        var filteredArtworks = InlineDb_artworks.filter(artwork => all_artists.includes(artwork.artist));
        if (quizid === "verylandscape")
        {
            if (logfetch) console.log("special example verylandscape");
            //filteredArtworks = InlineDb_artworks.filter(artwork => artwork.example & artwork.example==="verylandscape");
            filteredArtworks = InlineDb_artworks.filter(artwork => artwork.url==="https://www.nationalgallery.org.uk/media/gw3neewd/n-2919-00-000058-hd.jpg");
        }
        else if (quizid === "squarish")
        {
            if (logfetch) console.log("special example squarish");
            //filteredArtworks = InlineDb_artworks.filter(artwork => artwork.example & artwork.example==="verylandscape");
            filteredArtworks = InlineDb_artworks.filter(artwork => artwork.url==="https://www.nationalgallery.org.uk/media/33802/n-2908-00-000015-hd.jpg");
        }
        else if (quizid === "specific")
        {
            if (logfetch) console.log("special example specific");
            //filteredArtworks = InlineDb_artworks.filter(artwork => artwork.example & artwork.example==="verylandscape");
            filteredArtworks = InlineDb_artworks.filter(artwork => artwork.url===specific_url);
        }
        
        
        const howmanyartworks_filtered = filteredArtworks.length
        if (logfetch) console.log("filtered artworks length " + howmanyartworks_filtered);

        //
        // choose random artworks... first make a list of ids
        //
        while (artwork_ids.length < howmany_questions)
        {
            dbg9 = dbg9 + 1;
            if (dbg9>1000)
            {
                console.log("damn it now");
                break;
            }
            
            if (logfetch) console.log("filteredArtworks.length = " + filteredArtworks.length + ", artwork_ids.length = " + artwork_ids.length + ", howmanyartworks = " + howmanyartworks + ", all_artists.length = " + all_artists.length);
            const random_artwork_id = ~~(Math.random() * howmanyartworks_filtered);
            if (!artwork_ids.includes(random_artwork_id))
            {
                if (logfetch) console.log("ok - random_artwork_id = " + random_artwork_id);
                artwork_ids.push(random_artwork_id);
            }
        }
        artwork_ids.forEach( (id) => {
            // if (lograndom) console.log("foreach... " + id);
            // if (lograndom) console.log("InlineDb_artworks.length " + InlineDb_artworks.length);
            // if (lograndom) console.log("InlineDb_artworks[id] = " + InlineDb_artworks[id]);
            const one_artwork_info = filteredArtworks[id];
            set_selection_artwork_info((prevData) => [...prevData, one_artwork_info]);
            // if (lograndom) console.log("selection_artwork_info = " + selection_artwork_info);
            // if (lograndom) console.log("sofar: " + selection_artwork_info);
        }
        );
        if (logstatus) console.log("changing status to loaded");
        setStatusGameCreation("loaded");
    };

    const FetchAllArtworkData_ViaJsonServer = async () => {

        console.log("FetchAllArtworkData");
        const response1 = await fetch('http://localhost:8000/meta');
        const data1 = await response1.json();
        console.log("FetchAllArtworkData meta, data1[0]: " + data1[0]);
        const howmanyartworks = data1[0].howmanyartworks;

        //console.log("howmanyartworks = " + howmanyartworks)
        const response2 = await fetch(`http://localhost:8000/artists`);
        const data2 = await response2.json();
        console.log("FetchAllArtworkData artists, data2: " + data2);
        all_artists = data2;
        //console.log("all_artists_array = " + all_artists)

        if (howmany_questions > howmanyartworks)
        {
            howmany_questions = howmanyartworks;
        }
        var artwork_ids = [];
        while (artwork_ids.length < howmany_questions)
        {
            //console.log("artwork_ids.length = " + artwork_ids.length)
            const random_artwork_id = ~~(Math.random() * howmanyartworks);
            if (!artwork_ids.includes(random_artwork_id))
            {
                //console.log("random_artwork_id = " + random_artwork_id);
                artwork_ids.push(random_artwork_id);
            }
        }
        console.log("calling FetchOneArtworkDataFirstIdGivenRecursive " + artwork_ids);
        FetchOneArtworkDataFirstIdGivenRecursive(artwork_ids);
    };

    //
    // when status changes, advance to next stage
    //
    const OnStatusChange = () => 
    {
        //console.log("OnStatusChange status has become " + statusGameCreation);

        if (statusGameCreation === "restart")
        {
            FetchAllArtworkData();
            setCountCorrect(0);
            return;
        }

        // on startup, nothing to do yet
        if (selection_artwork_info.length === 0)
        {
            //console.log("OnStatusChange, no artworks yet");
            return;    
        }

        // when status becomes "populated" prepare the current question for display
        if (statusGameCreation === "populated")
        {
            //console.log("OnStatusChange, CueDisplayCurrentQuestion");
            CueDisplayCurrentQuestion();
            if (logstatus) console.log("changing status to pending");
            setStatusGameCreation("pending");
            return;
        }

        // when status becomes "loaded" prepare multiple choice for each question
        if (statusGameCreation === "loaded")
        {
            PrepareAllQuestions();
        }
    }

    //
    // PrepareAllQuestions
    // prepare multiple choice for each of theartworks in the chosen list (selection_artwork_info) 
    //
    const PrepareAllQuestions = () => 
    {
        if (selection_artwork_info.length === 0)
        {
            if (logprep) console.log("PrepareAllQuestions ... no selected artworks");
            return;
        }
        
        //console.log("PrepareAllQuestions; statusGameCreation = " + statusGameCreation + ", selection_artwork_info.length = " + selection_artwork_info.length);
        console.log(selection_artwork_info);
        
        if (logprep) console.log("PrepareAllQuestions ... " + JSON.stringify(selection_artwork_info)   );
        //
        // for each question in current quiz, make the multiple choice
        //
        selection_artwork_info.forEach(
            (one_artwork) => {
                if (logprep) console.log("PrepareAllQuestions ... " + JSON.stringify(one_artwork)   );
                if (logprep) console.log("  howmany_multichoice = " + howmany_multichoice);
                var some_artists = [];

                // put the correct artist at a random point in the multichoice list
                const idx_correct_answer = ~~(Math.random() * howmany_multichoice);
                var pushed_correct_answer = false;
                //console.log("all_artists.length=" + (all_artists.length));
                //console.log("idx_correct_answer=" + (idx_correct_answer));
                //console.log("one_artwork.artist=" + (one_artwork.artist));

                // fill the multichoice list
                var dbg = 0;
                if (howmany_multichoice > all_artists.length)
                {
                    howmany_multichoice = all_artists.length;
                }
                
                while (some_artists.length < howmany_multichoice)
                {
                    // don't loop forever
                    dbg++;
                    if (dbg>1000000)
                    {
                        if (logprep) console.log("damn it all; didn't find enough artists for multichoice from " + all_artists.length);
                        break;
                    }
                    
                    //console.log("attempt " + dbg + "; howmany_multichoice = " + howmany_multichoice + "; some_artists.length = " + some_artists.length);
                    if (some_artists.length === idx_correct_answer) // zeroth prblem?
                    {
                        if (one_artwork)
                        {
                            if (lograndom) console.log("placed the correct artist at " + idx_correct_answer + " " + one_artwork.artist)
                            some_artists.push(one_artwork.artist);
                            pushed_correct_answer = true;
                        }
                    }
                    else
                    {
                        const idx = ~~(Math.random() * all_artists.length);
                        //console.log("random artist idx = " + idx)
                        const one_artist = all_artists[idx];
                        //console.log("one_artist = " + one_artist);
                        //console.log("trying one_artist=" + (one_artist));
                        if (!one_artwork)
                        {
                            // no artwork
                        }
                        else if (one_artwork.artist === one_artist)
                        {
                            //console.log("ignore, random artist is the correct artist, " + one_artist);
                        }
                        else if (some_artists.includes(one_artist))
                        {
                            //console.log("already placed random artist " + one_artist);
                        }
                        else
                        {
                            //console.log("placing random artist " + one_artist);
                            some_artists.push(one_artist);
                        }
                    }
                }
                if (one_artwork)
                {
                    const jsonObject = {
                        correct_answer: one_artwork.artist,
                        possible_answers: some_artists,
                        image_url: one_artwork.url,
                        artworkid: one_artwork.id
                    }       
                    //console.log("json_questions push " + one_artwork.url);
                    json_questions.push(jsonObject);
                    if (!pushed_correct_answer)
                    {
                        console.log("failed to push correct answer");
                    }
                }
                else {
                    console.log("no artwork");
                }                
            }    
        )
        //console.log("json_questions = " + JSON.stringify(json_questions));
        if (logstatus) console.log("changing status to populated");
        setStatusGameCreation("populated");
    };

    // 
    // GetOneQuestion
    // return the next question in  the array
    // 
    const GetOneQuestion = () => json_questions.pop();

    const LookaheadNextQuestion = () => {
        //return null;
        //todo if I reinstate the following then it affects the img calculation

        if (json_questions.length === 0)
            return null;
        else
        {
            return json_questions[json_questions.length-1];
        }
    }
    

    //
    // OnClickMultipleChoice
    // when user clicks an answer, record their answer
    //
    const OnClickMultipleChoice = (setAnswer, answer, e) => {
        if (answer === "")
        {
            setAnswer(e.artist);
        }
        else if (answer !== e.artist)
        {
            alert("already answered");
        }
    }

    //
    // CueDisplayCurrentQuestion
    // display the current question
    //
    const CueDisplayCurrentQuestion = () =>
    {
        const question = GetOneQuestion();
        //console.log("CueDisplayCurrentQuestion length=" + json_questions.length);
        //console.log("  one is " + question.image_url);
        if (question)
        {
            setAnswer('');
            setImageUrl(question.image_url);
            setPossibles(question.possible_answers);
            setCorrectArtist(question.correct_answer);
            setFinalScore("");

            const nextquestion = LookaheadNextQuestion();
            if (nextquestion)
            {
                setNextImageUrl(nextquestion.image_url);
            }
        }
        else
        {
            setAnswer('');
            setImageUrl("");
            setPossibles([]);
            setCorrectArtist("");
            setFinalScore("" + CountCorrect + "/" + howmany_questions);
        }
    }

    const id_forwhich_game_is_waiting = "artworkimg"; // todo alternate

    const OnImgLoaded = (imgid) => {
        if (logimg) console.log("img onLoad");

        if (imgid === id_forwhich_game_is_waiting && statusGameCreation === "pending")
        {
            //console.log("image has loaded, changing status from " + statusGameCreation + " to ready");
            setStatusGameCreation("ready");
        };

        const myImage = document.getElementById(imgid);
        if (logimg) console.log(myImage.src);
        getIntrinsicDimensions(myImage)
        .then((img_dimensions) => {
            //const divobj = document.getElementById("paintingdiv");
            //const viewport_width = window.innerWidth;//divobj.clientWidth;
            if (viewport_width === 0) {
                const element = document.getElementById("hiddenoverlay");
                viewport_width = element.clientWidth;
                viewport_height = element.clientHeight;
                element.style.display = "none";
                // these 2048, 951, not same as screen res.
                if (logimg) console.log("viewport_width = " + viewport_width);
                if (logimg) console.log("viewport_height = " + viewport_height);
            }
            const choicecell1 = document.getElementById("choicecell");
            //if (logimg) console.log("choicecell.height = " + choicecell.height);
            //if (logimg) console.log("choicecell.offsetHeight = " + choicecell.offsetHeight);
            const sixlines_height = getLineHeightUsingDiv(choicecell1);
            if (logimg) console.log("sixlines_height = " + sixlines_height);
            
            //const viewport_height = Math.floor(window.innerHeight/2);//divobj.clientHeight;
            //const choiceheight = getLineHeightUsingDiv(document.getElementById("choicediv")) * (howmany_multichoice+2/*for the NEXT button*/ + 6/*for debug info*/);
            //if (logimg) console.log("choiceheight = " + choiceheight);
            //if (logimg) console.log("window.innerHeight = " + window.innerHeight);
            if (logimg) console.log("viewport_width, height = " + viewport_width + ", " + viewport_height);
            //const viewport_height = Math.floor(window.innerHeight - choiceheight - 50 );//divobj.clientHeight;

            if (logimg) console.log("Intrinsic WidthxHeight:" +  img_dimensions.width + " x " + img_dimensions.height);
            if (logimg) console.log("quizparent.height = " + document.getElementById("quizparent").clientHeight);
            if (logimg) console.log("quizparent.width = " + document.getElementById("quizparent").clientWidth);
            if (logimg) console.log("Div w x Height:", viewport_width + " x " + viewport_height);
            // todo, maybe need to rethink, look at space for text too

            var suggested_width = 0;
            var suggested_height = 0
            if (img_dimensions.height > img_dimensions.width)
            // todo maybe still compare vs "available viewport" aspectratio?
            // > viewport_height / viewport_width)
            {  
                // image is "portrait" and screen is "landscape"
                // put the image and answers side by side horizontally
                if (logimg) console.log("portrait image");
                const vgap = 20;
                const hgap = 30;
                // // try to maximise the width
                // suggested_width = Math.floor(viewport_width - hgap) * portion_horizontal_for_img;
                // // todo, measure the text of the choice instead of this 0.66
                // suggested_height = Math.floor(suggested_width * dimensions.height / dimensions.width);
                // if (suggested_height > viewport_height)
                // {
                //     if (logimg) console.log("suggested_height too big " + suggested_height + " > " + viewport_height);
                //     suggested_height = viewport_height - sixlines_height;
                //     suggested_height = suggested_height;
                //     suggested_width = Math.floor(dimensions.width * suggested_height / dimensions.height);
                //     if (logimg) console.log("new suggested_height " + suggested_height);
                // }                                                       
                
                // try to maximise the height
                suggested_height = viewport_height - vgap;
                suggested_width = img_dimensions.width * suggested_height / img_dimensions.height;
                const max_horizontal_space_for_img = Math.floor(viewport_width - hgap) * portion_horizontal_for_img;
                if (suggested_width > max_horizontal_space_for_img) {
                    if (logimg) console.log("suggested_width too big " + suggested_width + " > " + max_horizontal_space_for_img);
                    suggested_width = max_horizontal_space_for_img;
                    suggested_height = suggested_width * img_dimensions.height / img_dimensions.width;
                    if (logimg) console.log("new suggested_width " + suggested_width);
                }

                MoveCellsIntoHorizontalTable();
                myImage.style.height="";
                myImage.style.width="";
                myImage.height=suggested_height;
                myImage.width=suggested_width;
                // if (logimg) console.log("setting image height = " + viewport_height + " minus " + gap);
            }
            else
            {
                // image is more "landscape" so put the answers below the image
                if (logimg) console.log("landscape image");
                const vgap = 130;
                const hgap = 20;

                // try to maximise the height
                suggested_height = viewport_height - sixlines_height - vgap;
                suggested_width = Math.floor(suggested_height * img_dimensions.width / img_dimensions.height);
                if (suggested_width > viewport_width - hgap)
                {
                    suggested_width = viewport_width - hgap;
                    suggested_height = Math.floor(img_dimensions.height * suggested_width / img_dimensions.width);
                }                                                       

                // landscape; screen is taller than the image
                MoveCellsIntoVerticalTable();
                //var suggested_width = viewport_width - gap;
                //const remaining_v_space = viewport_height - sixlines_height;
                //if (logimg) console.log("viewport_height = " + suggested_width);
                // if (logimg) console.log("choicecell = " + choicecell);
                // if (logimg) console.log("choicecell.height = " + choicecell.height);
                if (logimg) console.log("suggested_width = " + suggested_width);
                //if (logimg) console.log("-- img height will be " + ((dimensions.height / dimensions.width) * suggested_width));
                //if (logimg) console.log("remaining_v_space = " + remaining_v_space);
                
                //choicecell.height
                // if ((dimensions.height / dimensions.width) * suggested_width > remaining_v_space)
                // {
                //     suggested_width = Math.floor((viewport_height - sixlines_height) / (dimensions.height / dimensions.width));
                //     if (logimg) console.log("new suggested_width < " + suggested_width);
                //     if (logimg) console.log("means img height will be " + ((dimensions.height / dimensions.width) * suggested_width));
                // }
                // else {
                //     if (logimg) console.log("ok img height will be " + ((dimensions.height / dimensions.width) * suggested_width) + "  < " + remaining_v_space);
                // }
                
                myImage.style.height="";
                myImage.style.width="";
                myImage.height=suggested_height;
                myImage.width=suggested_width;
            
                //myImage.width = 200;
            }
            if (logimg) console.log("setting image width = " + suggested_width + "; myImage.height=" + myImage.height + "; myImage.width=" + myImage.width + "; myImage.style.height=" + myImage.style.height + " myImage.style.width=" + myImage.style.width);
            //myImage.width=viewport_width;
            //myImage.height=viewport_height;                                                    
            UnhideImg();
        })
        .catch((error) => {
            console.error("Error getting image dimensions:", error);
        });
    }
    

    //
    // LOGIC
    //

    // set hook so that when page loads, fetch data for a random selection of artworks, then change status to "loaded"
    useEffect(() => {
        if (quizid === "specificsequence")
        {
            InlineDb_artworks.forEach(obj => {
                if (!all_artists.includes(obj.artist)) {
                    all_artists.push(obj.artist);
                }
            });
            howmany_questions = specificsequence.length;
        }
        else if (quizid === "verylandscape")
        {
            all_artists = verylandscape;
            howmany_questions = 1;
        }
        else if (quizid === "squarish")
        {
            all_artists = squarish;
            howmany_questions = 1;
        }
        else if (quizid === "specific")
        {
            all_artists = specific;
            howmany_questions = 1;
        }
        else if (quizid === "impressionist")
        {
            all_artists = InlineDb_impressionist;            
        }
        else if (quizid === "renaissance") {
            all_artists = renaissanceArtists;
        }
        else if (quizid === "baroque") {
            all_artists = InlineDb_Baroque;
        }
        else if (quizid === "romanticism") {
            all_artists = romanticismArtists;
        }
        else if (quizid === "symbolism") {
            all_artists = symbolismArtists;
        }
        else if (quizid === "neoclassicism") {
            all_artists = neoclassicismArtists;
        }
        else if (quizid === "postimpressionism") {
            all_artists = postImpressionismArtists;
        }
        else if (quizid === "realism") {
            all_artists = realismArtists;
        }
        else if (quizid === "rococo") {
            all_artists = rococoArtists;
        }
        else if (quizid === "cubism") {
            all_artists = cubistArtists;
        }
        else if (quizid === "expressionism") {
            all_artists = expressionistPainters;
        }
        else if (quizid === "surrealism") {
            all_artists = surrealistPainters;
        }
        // else if (quizid === "xxx") {
        //     all_artists = painters;
        // }
        else if (quizid === "all") {
            all_artists = InlineDb_artists;
        }
        else
        {
            all_artists = InlineDb_impressionist;
            console.log("default impressionist");
        }
               
        FetchAllArtworkData();
    }, []);

    // set hook so that when status changes, advance to next stage
    useEffect(
        () => {OnStatusChange();}, [statusGameCreation, OnStatusChange]
    );

    // compiler warning: The 'MyFunc1' function makes the dependencies of useEffect Hook (at line 366) change on every render. Move it inside the useEffect callback.
    // compiler warning: React Hook useEffect has a missing dependency: 'MyFunc1'. Either include it or remove the dependency array 


    // why doesn't this work in the click?
    //setCountCorrect("1234");

    var stylebit = {};
    if (statusGameCreation!=="ready")
    {
        stylebit = {display: "none", margin: "0 auto"};
    }
    else
    {
        stylebit = {display: "block", margin: "0 auto"}; // todo
    }

    //
    // generate the Play component
    //
    return (
        <div className="quiz">
            {false && quizid && <p>quizid = {quizid}</p>}
            {false && selection_artwork_info.length>0 && <p>your score so far: {CountCorrect} / {howmany_questions}; remaining items: {json_questions.length}; </p>}
            {show_remaining && selection_artwork_info.length>=1 && <p>remaining item 1 {selection_artwork_info[0].artist}</p>}
            {show_remaining && selection_artwork_info.length>=2 && <p>remaining item 2 {selection_artwork_info[1].artist}</p>}
            {/* {
            FinalScore==="" && */}

            <div jk_cue={statusGameCreation}>
                <div className="preloadhiddenoverlay" id="preloadhiddenoverlay">
                    <div className="hiddenoverlay-content" id="hiddenimgparent">
                    <img src={next_imageurl_s} alt="preloadimg" style={stylebit}/>
                    </div>
                </div>
                <div className="hiddenoverlay" id="hiddenoverlay">
                    <div className="hiddenoverlay-content" id="dummy">
                    </div>
                </div>

                {
                    FinalScore && <div style={{textAlign:"center", color:"white"}}><h1>Your score is {FinalScore}</h1><button className="btnism" id="btnAgain" onClick={
                    () => {
                        HideImg();
                        if (logstatus) console.log("changing status to populated during play again");
                        set_selection_artwork_info([]);
                        setStatusGameCreation("restart");
                    }}
                    >PLAY AGAIN</button> 
                    <a href="/">
                        <button className="btnism">HOME
                        </button>
                    </a>
                    </div>
                }
                <div id="hiddentableparent" className="hiddentableparent" style={{display:"none", textAlign:"right"}}>
                    <div className="box" id="paintingcell" style={{textAlign:"right"}}>
                        <div className="painting" style={{textAlign:"right"}} id="paintingdiv" >
                        <img src={imageurl_s} style={stylebit} alt={statusGameCreation} id="artworkimg"
                                        onLoad = {
                                            () => {
                                                OnImgLoaded("artworkimg");
                                            }
                                        }
                                    />
                        </div>
                    </div>
                    <div className="box" id="choicecell">
                        <div className="choice" id="choicediv">
                            <div className="artists" style={stylebit}>
                            {possibles.map((artist,index) => (
                                    <div  key={index} mycode="todo">
                                    <label  onClick={()=>{OnClickMultipleChoice(setAnswer, answer, {artist});}} id={"answer"+(index+1)}>
                                    {index+1} {artist}
                                    {answer === "" && " ?"}
                                    {answer !== "" && answer === artist && answer === correct_artist && <span className="correctanswer"> -- CORRECT! :-)</span>}
                                    {answer !== "" && answer === artist && answer !== correct_artist && <span className="wronganswer"> -- WRONG :-(</span>}
                                    {answer !== "" && artist === correct_artist && answer !== correct_artist && <span className="actualanswer"> -- the correct answer</span>}
                                    </label>
                                    </div>
                                ))}
                            </div>  {/* artist */}
                        </div>  {/* choice */}
                        {answer !== "" && <button id="btnNext" className="btnism" onClick={()=>{
                            HideImg();
                            if (correct_artist === answer)
                            {
                                setCountCorrect(CountCorrect+1);
                                //setCountCorrect(1234);
                            }
                            if (logstatus) console.log("changing status to populated, during next");
                            setStatusGameCreation("populated");
                            //CueDisplayCurrentQuestion(); // NEXT
                        }} >NEXT</button>}
                        </div>  {/* choicecell */}
                </div>
                <div id="quizparent" className="quizparent">
                    <table id="horizontaltable"><tbody>
                        <tr>
                            <td style={{width:"80%"}}><div id="horizontalpainting"/></td>
                            <td style={{width:"20%"}}><div id="horizontalchoice"/></td>
                        </tr>
                    </tbody></table>
                    <table id="verticaltable"><tbody>
                        <tr>
                            <td><div id="verticalpainting" className="verticalpainting" /></td>
                        </tr>
                        <tr>
                            <td><div id="verticalchoice" className="verticalchoice" /></td>
                        </tr>
                    </tbody></table>
                </div>  {/* quizparent */}
            </div>  {/* quiz */}

        </div>
    )
}
export default Play
// todo next: problem, during NEXT, the jpg takes a while to arrive, and question is local and fast and already there
//  perhaps, get meta info, such as total number of images
//  assume they are indexed sequentially (how to automatically assign sequential id within json server?)
//  random number to pick an image, load the image info from the json server

// todo, hidden img to load the next jpg, in advance, do that after the current one loads

// todo, e.g. expressionism, when there are no artworks, crashes during foreach, seems like a null was pushed onto the list?


// todo, HOME button, from PLAY
// todo, move the "your score", put it next to the multichoice (horizontally for landscape images, vertically otherwise)

// todo, adjust the image size, after setting the choice text, (how to know when that is done?)
//    so if the text goes offscreen, sum of the heights of the two cells (vertical mode)
//       then reduce the image accordingly

